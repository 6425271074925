import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

import WarningIcon from "../../../resources/images/warning.png";

//director
import Medalla from "../../../resources/images/Medalla.png";
import DirectorMediun from "../../../resources/images/DirectorMediun.png";

import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Candidate from "../../../resources/images/candidate.png";
import NewHost from "../../../resources/images/new-host.png";

import Genial from "../../../resources/images/Genial.png";
import BackProvider from "../../../resources/images/Back2.png";
import Back from "../../../resources/images/Back.png";

//end of director

import GoodHandDirector from "../../../resources/images/good-hand-director.png";

import KeyH1 from "../../../resources/images/KeyH1.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { CardCopyKey } from "../../molecules/card-copy-key";

import { Quote } from "../../molecules/quote";
import "./circle.scss";
import Card from "../../atoms/card";

const DirectorSecondDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "39px",
              }}
            >
              <b>¡Hey!</b>
            </Paragraph>
            <img
              src={WarningIcon}
              alt="sync-aspirante-director"
              style={{
                marginTop: "10px",
              }}
            />

            <Paragraph withBackground={true} align="center" size="s">
              <b>
                Este desafío se realiza por fuera de la
                <br />
                Plataforma ACDC.{" "}
              </b>
              
              <br />
              <br />
              Cuando tu Capitán(a) reciba y confirme
              <br />
              que le enviaste la nota de voz
              <br />
              correspondiente al <b>Desafío A</b> podrás
              <br />
              continuar con tus desafíos diarios.
            </Paragraph>
            <br />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              ¡Vale!
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Medalla} alt="medalla" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b>Justo a tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={DirectorMediun} alt="DirectorMediun" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b> Manejo del tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="182"
              height="182"
            />
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <Paragraph size="s" align="center">
              Ya puedes continuar con el <b>Desafío B</b> del
              <br /> <b>Día 2.</b>
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              ¡Vale!
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>HAMBRE Formación de líderes.</b>
                  <br />
                  <br />
                  “Estar ocupado a causa de prioridades<br/>
                   incorrectas es un bloqueo que se<br/>
                  encuentra con frecuencia en el camino<br/>
                  del aprendizaje de un líder. Cualquier<br/>
                  líder debe estar al tanto de esto y no<br/>
                  debe permitir que las responsabilidades<br/>
                  del día a día oscurezcan su habilidad de<br/>
                  aprender y crecer.”<br/>
“No estar abierto a aprender es mucho<br/>
 más una actitud que cualquier otra cosa.<br/>
  La actitud de sabelotodo es la sentencia<br/>
   de muerte para el logro.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              Revisa el progreso de tus Coordinadores y los
              <br />
              Anfitriones que están en <b>Día 2.</b>
              <br />
            </Paragraph>
            <main>
              <div class="icon-container">
                <div class="icon">
                  <img
                    src={CoordinatorDirector}
                    alt="CoordinatorDirector"
                    width="145"
                    height="145"
                  />
                </div>
                <div
                  class="icon"
                  style={{
                    marginTop: "75px",
                    marginLeft: "-48px",
                  }}
                >
                  <img src={NewHost} alt="new-host" width="111" height="111" />
                </div>
                <div
                  class="icon"
                  style={{
                    marginTop: "131px",
                    marginLeft: "-68px",
                  }}
                >
                  <img src={Candidate} alt="Candidate" width="82" height="82" />
                </div>
              </div>
            </main>

            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "-12px",
              }}
            >
              Podrás guiarte con los tags que indican el día en
              <br />
              que se encuentran los Aspirantes:
            </Paragraph>
            <Paragraph align="center" size="sx">
              <span className="text-candidate">Inducción</span>
              <span className="text-candidate">Día de Reglas</span>
            </Paragraph>
            <Paragraph align="center" size="sx">
              <span className="text-candidate">Día 1</span>
              <span className="text-candidate">Día 2</span>
              <span className="text-candidate">Día 3</span>
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <img
                src={CoordinatorDirector}
                alt="CoordinatorDirector"
                width="145"
                height="145"
              />
              <br />
              <b>
                Luis Carlos
                <br />
                Sarmiento Angulo
              </b>
              <br />
              Coordinador(a)
              <br />
            </Paragraph>

            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                background: "#0D233B",
                height: "100px",
              }}
            >
              {" "}
              <div className="container-card-host">
                <img src={BackProvider} alt="back" className="back-img-host" />

                <div
                  className={`menu-user-info menu-user-info--host row 
                        `}
                >
                  <div className={`progress-7-8 small`}>
                    <img src={NewHost} alt="new-host" width="90" height="90" />
                  </div>
                  <div className="menu-user-info-title">
                    <h4 className={`heading `}>Paula Inés Panesso Umbarila</h4>
                    <span>Anfitrión(a)</span>
                  </div>
                </div>
                <img src={Back} alt="back" className="back-img-host" />
              </div>
              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en: <span className="text-candidate">Día 2</span>
                  </span>
                </div>
              </div>

              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en:{" "}
                    <span className="text-candidate">Día de Reglas</span>
                  </span>
                </div>
              </div>
              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en: <span className="text-candidate">Día 2</span>
                  </span>
                </div>
              </div>
              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en:{" "}
                    <span className="text-candidate">Día 2</span>
                  </span>
                </div>
              </div>
              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en:{" "}
                    <span className="text-candidate">Día 1</span>
                  </span>
                </div>
              </div>
              <br />
            </Card>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
                marginTop: "-25px",
                height: "100px",
              }}
            >
              <div className={`menu-user-info menu-user-info--candidate row`}>
                <div className={` progress-7-8 small`}>
                  <img src={Candidate} alt="new-host" width="90" height="90" />
                </div>
                <div className="menu-user-info-title">
                  <span className={`heading `}>
                    Juan Pablo
                    <br />
                    Cuartas
                  </span>
                  <br />
                  <span>
                    Está en: <span className="text-candidate">Día 1</span>
                  </span>
                </div>
              </div>

              <br />
            </Card>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
              style={{
                marginTop: "19px",
              }}
            >
              ¡Genial!
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={KeyH1}
              alt="KeyH1"
              style={{
                marginTop: "10px",
              }}
            />
            <CardCopyKey
              phrase="Desafío B Día 2 Director"
              nameChallenge="Desafío B"
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>HULMIDAD Formación de líderes.</b>
                  <br />
                  <br />
                  “Los líderes se orientan hacia<br/>
                   situaciones y retos con una actitud de<br/>
                   aprendizaje como un estudiante, no<br/>
                   como un criticón. El ser criticón es uno<br/>
                   de los atributos comunes de los<br/>
                  fracasados, los cuales, de manera<br/>
                  rápida encuentran los defectos de los<br/>
                   otros o el lado negativo de las<br/>
                  situaciones. Cualquier tonto puede<br/>
                  encontrar un defecto, pero se necesita a<br/>
                   un ganador para encontrar una<br/>
                    solución.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              widh="343"
              height="209"
            />
            <CardCopyKey
              phrase="Desafío C Día 2 Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default DirectorSecondDay;
