import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

//director
import Union from "../../../resources/images/union.png";

import Genial from "../../../resources/images/Genial.png";
import Info from "../../../resources/images/Info.png";
import ContenedorZonaAprendizajeThree from "../../../resources/images/ContenedorZonaAprendizajeThree.png";

//end of director

import ContenedorZonaAprendizaje from "../../../resources/images/Contenedor_Zona_Aprendizaje.png";
import ContenedorZonaAprendizajeTwo from "../../../resources/images/ContenedorZonaAprendizaje2.png";
import ContenedorZonaAprendizajeFour from "../../../resources/images/ContenedorZonaAprendizajeFour.png";
import ContenedorTutorial from "../../../resources/images/ContenedorTutorial.png";
import Contenedorcrono from "../../../resources/images/Contenedorcrono.png";
import LearningZone from "../../../resources/images/Zona_aprendizaje.png";
import GoodHandDirector from "../../../resources/images/good-hand-director.png";

import CalendarIcon from "../../../resources/images/calendarTwo.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

import { CardCopyKey } from "../../molecules/card-copy-key";
import CalendarDirectorIcon from "../../../resources/images/Calendario_director.png";

import { Quote } from "../../molecules/quote";
import "./options.scss";
import MenuUserInfo from "../../molecules/menu-user-info";
import Card from "../../atoms/card";
import TextField from "../../atoms/text-field";
import { TextFieldList } from "../../molecules/text-field-list";

const DirectorRulesDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);
  const [hostActive, setHostActive] = useState([]);
  const [infoIndex, setInfoIndex] = useState(0);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx">
                  <b>
                    El arte (los pensamientos) para el Desarrollo de Líderes
                    consiste en:
                  </b>
                  <br />
                  <br />
                  <b>A.</b> Saber que los resultados llegarán a través de otros
                  líderes.
                  <br />
                  <br />
                  <b>B.</b> Entender el PODER de la duplicación.
                  <br />
                  <br />
                  <b>C. </b>Reconocer que los líderes tienen fortalezas en
                  diferentes áreas. <br />
                  <br />
                  <b>D. </b>Saber que la visión debe ser lo
                  <br />
                  suficientemente grande para sostener a<br /> muchos líderes.
                  <br />
                  <br />
                  <b>E. </b>Saben que el reconocimiento es el motivador más
                  valioso.
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>
                ¿Lograste comprender la importancia....?
                <br />
                ¡Veamos!
              </b>
            </Paragraph>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
              }}
            >
              Relaciona cada frase con la que le corresponde <br />
              (A, B, C, D o E):
            </Paragraph>
            <Paragraph
              align="center"
              size="sx"
              style={{
                maxWidth: "346px",
              }}
            >
              Clic sobre el campo al principio de cada frase para elegir una
              opción.
            </Paragraph>
            <div className="director-option-container">
              <span className="director-symbol">?</span>
              <span className="director-text-options">
                La productividad aumenta
                <br />
                exponencialmente.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">?</span>
              <span className="director-text-options">
                Tu capacidad de formar otros líderes es
                <br />
                lo que va a traer resultados de
                <br />
                crecimiento exponencial, pues cada líder
                <br />
                multiplica.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">?</span>
              <span className="director-text-options">
                No escatiman en reconocimiento para
                <br />
                sus líderes, saben el efecto que tiene un
                <br />
                reconocimiento de un líder sobre sus
                <br />
                resultados.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">?</span>
              <span className="director-text-options">
                Se preocupan más por su visión que por
                <br /> su gloria personal.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">?</span>
              <span className="director-text-options">
                El proyecto “Cali la Sucursal del Cielo”
                <br />
                tiene espacio para todos, hay tareas
                <br />
                para todos y sus talentos.
              </span>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 3}
            onClose={() => setOpen(false)}
            className="list-modal"
            style={{
              height: "600px",
            }}
          >
            <Paragraph align="center" size="sx">
              Clic sobre la opción que deseas elegir
            </Paragraph>

            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
            >
              <div
                className="drag-item__info"
                onClick={() => setStep(step + 1)}
              >
                <h1 className="drag-item__title">A.</h1>
                <Paragraph>
                  Saber que los resultados llegarán a<br /> través de otros
                  líderes.
                </Paragraph>
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
            >
              <div
                className="drag-item__info"
                onClick={() => setStep(step + 1)}
              >
                <h1 className="drag-item__title">B.</h1>
                <Paragraph>
                  Entender el PODER de la
                  <br /> duplicación.
                </Paragraph>
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
            >
              <div
                className="drag-item__info"
                onClick={() => setStep(step + 1)}
              >
                <h1 className="drag-item__title">C.</h1>
                <Paragraph>
                  Reconocer que los líderes tienen
                  <br /> fortalezas en diferentes áreas.
                </Paragraph>
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
            >
              <div
                className="drag-item__info"
                onClick={() => setStep(step + 1)}
              >
                <h1 className="drag-item__title">D.</h1>
                <Paragraph>
                  Saber que la visión debe ser lo
                  <br /> suficientemente grande para
                  <br /> sostener a muchos líderes.
                </Paragraph>
              </div>
            </div>

            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
            >
              <div
                className="drag-item__info"
                onClick={() => setStep(step + 1)}
              >
                <h1 className="drag-item__title">E.</h1>
                <Paragraph>
                  Saben que el reconocimiento es el
                  <br /> motivador más valioso.
                </Paragraph>
              </div>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>
                ¿Lograste comprender la importancia....?
                <br />
                ¡Veamos!
              </b>
            </Paragraph>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
              }}
            >
              Relaciona cada frase con la que le corresponde <br />
              (A, B, C, D o E):
            </Paragraph>
            <Paragraph
              align="center"
              size="xs"
              style={{
                maxWidth: "364px",
              }}
            >
              Clic sobre el campo al principio de cada frase para
              <br /> elegir una opción.
            </Paragraph>

            <div className="director-option-container">
              <span className="director-symbol">B</span>
              <span className="director-text-options">
                La productividad aumenta
                <br />
                exponencialmente.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">A</span>
              <span className="director-text-options">
                Tu capacidad de formar otros líderes es
                <br />
                lo que va a traer resultados de
                <br />
                crecimiento exponencial, pues cada líder
                <br />
                multiplica.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">E</span>
              <span className="director-text-options">
                No escatiman en reconocimiento para
                <br />
                sus líderes, saben el efecto que tiene un
                <br />
                reconocimiento de un líder sobre sus
                <br />
                resultados.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">D</span>
              <span className="director-text-options">
                Se preocupan más por su visión que por
                <br /> su gloria personal.
              </span>
            </div>
            <div className="director-option-container">
              <span className="director-symbol">C</span>
              <span className="director-text-options">
                El proyecto “Cali la Sucursal del Cielo”
                <br />
                tiene espacio para todos, hay tareas
                <br />
                para todos y sus talentos.
              </span>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
            </Paragraph>
            <Paragraph size="s" align="center" style={{ maxWidth: "364px" }}>
              Te queda poco para completar el <b>Desafío A.</b>
            </Paragraph>
            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              widh="226"
              height="226"
            />
            <CardCopyKey
              phrase="Pensamientos Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Ciencia Formación de Líderes.</b>
            </Paragraph>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
              }}
            >
              La ciencia está relacionada con las acciones y en
              <br /> este rol son:
            </Paragraph>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <Paragraph>
                  Inspirar a otros líderes a lograr
                  <br /> resultados en equipo.
                </Paragraph>
              </div>
              <h1
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                <img src={Info} alt="info" />
              </h1>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(2)}
            >
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <Paragraph>Convertirte en un cazatalentos.</Paragraph>
              </div>
              <h1
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                <img src={Info} alt="info" />
              </h1>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(3)}
            >
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <Paragraph>
                  Identificar el liderazgo de tus
                  <br />
                  Coordinadores y empoderarlos.
                </Paragraph>
              </div>
              <h1
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                <img src={Info} alt="info" />
              </h1>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(4)}
            >
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <Paragraph>Aprender a ser un Mentor.</Paragraph>
              </div>
              <h1
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                <img src={Info} alt="info" />
              </h1>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Paragraph
              align="center"
              size="xs"
              style={{
                width: "364px",
                maxWidth: "364px",
              }}
            >
              Para poder continuar, activa cada acción revisando la
              <br />
              información dando clic en cada uno de los botones.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "36px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={infoIndex !== 0}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Info} alt="info" />
              {infoIndex === 1 && (
                <>
                  <Paragraph align="center" size="s">
                    Hoy tu Coordinador(a) podrá apoyar el
                    <br />
                    <b
                      style={{
                        color: "blue",
                      }}
                    >
                      Plan B Convocatoria
                    </b>{" "}
                    de los Anfitriones
                    <br />
                    para lograr la Meta 1 y tú podrás apoyar a<br />
                    tu Coordinador(a) con
                    <b
                      style={{
                        color: "blue",
                      }}
                    >
                      {" "}
                      La Lista de Espera.
                    </b>
                    <br />
                    <br />
                  </Paragraph>
                  <Button size="small" onClick={() => setInfoIndex(0)}>
                    ¡Vale!
                  </Button>
                  <Paragraph
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    No sé qué es Plan B Convocatoria.
                    <br />
                    <br />
                    No sé que es La Lista de Espera.
                  </Paragraph>
                </>
              )}

              {infoIndex === 2 && (
                <>
                  <Paragraph align="center" size="s">
                    Podrás apoyar a tu Coordinador(a) con la
                    <br />
                    <b>Lista de Espera</b> de la Convocatoria.
                    <br />
                    <br />
                  </Paragraph>
                  <Button size="small" onClick={() => setInfoIndex(0)}>
                    ¡Vale!
                  </Button>
                </>
              )}

              {infoIndex === 3 && (
                <>
                  <Paragraph align="center" size="s">
                    Podrás apoyar a tu Coordinador(a) con la
                    <br />
                    <b>Lista de Espera</b> de la Convocatoria.
                    <br />
                    <br />
                  </Paragraph>
                  <Button size="small" onClick={() => setInfoIndex(0)}>
                    ¡Vale!
                  </Button>
                </>
              )}
              {infoIndex === 4 && (
                <>
                  <Paragraph align="center" size="s">
                    Se un guía cumpliendo el paso a paso para
                    <br /> tu rol.
                  </Paragraph>
                  <Button size="small" onClick={() => setInfoIndex(0)}>
                    ¡Vale!
                  </Button>
                </>
              )}
            </div>
          </Modal>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              Es tu momento de sacar lo mejor de tus
              <br />
              Coordinadores en juego, de empoderarlos, de
              <br />
              llevarlos a que crean en ellos mismos, de
              <br />
              retarlos, de conectar con sus PDL, de pedirles
              <br />
              permiso para sacarlos de sus propios límites,
              <br />
              confiar en ellos y generar confianza por medio
              <br />
              de tu honorabilidad, honrando tu palabra con
              <br />
              los tiempos que acuerdan y respondiéndoles con
              <br />
              pasión sus audios de las lecturas.
            </Paragraph>
            <Paragraph align="center" size="sx">
              Lograr que completen su rol como
              <br />
              Coordinador(a) llevando a sus Anfitriones a<br />
              alcanzar resultados, ejemplifica los proyectos
              <br />
              que estamos a punto de liderar. No
              <br />
              subestimemos la capacidad del Coordinador(a)
              <br />
              para manejar un EPA con los dos Anfitriones
              <br />
              completos. Estamos destinados a lograr
              <br />
              grandes cosas, siempre aspiremos a la
              <br />
              grandeza, fomentando la confianza y la
              <br />
              inspiración constantemente. Dirige y escucha
              <br />
              atentamente.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <b>Actividad Lista de espera convocatoria.</b>
            </Paragraph>
            <img src={Union} alt="union" />
            <Paragraph align="center" size="sx">
              Verifica en la pestaña de <b>Mi Desempeño</b> si tu
              <br />
              Coordinador(a) ya logró la Meta 1.
              <br />
              <br />
              Recuerda que los Anfitriones de tu
              <br />
              Coordinador(a) están aplicando el ABC
              <br />
              Convocatoria Plan B.
              <br />
              <br />
              Quien no lo haya logrado pero que tenga al
              <br />
              menos 4 Agendados se le asignará la lista de
              <br />
              espera del Coordinador(a) y de ser necesario se
              <br />
              activará el protocolo de Lista de Espera
              <br />
              convocatoria para el rol de Director y Capitán.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <b>Anfitrión # 1</b>
              <br />
              de <br />
              🔴 Luis Carlos Sarmiento Angulo:
            </Paragraph>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
              }}
            >
              <MenuUserInfo
                name={toLowercaseAndCapitalize("Flabio Hinestroza")}
                role="host"
                nameRole="Anfitrión"
                range={7}
                orientation="row"
                size="small"
              />
              <Paragraph align="center" size="sx">
                Requiere de <strong style={{ color: "#4DFFDD" }}>2</strong>{" "}
                personas de Lista de Espera.
              </Paragraph>
            </Card>
            <TextFieldList>
              <TextField
                disabled={true}
                placeholder="Selecciona un  posible Aspirante."
                value=""
                rightIcon={{
                  icon: "icon-add",
                  size: 24,
                  color: "#0063DD",
                  onClick: () => {
                    setOpen(true);
                  },
                }}
              />
            </TextFieldList>
            <Paragraph align="center" size="sx">
              A continuación, elige las posibles personas que
              <br />
              usará tu Coordinador(a) de tu Lista de Espera
              <br />
              para ayudar a su Anfitrión(a):
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            className="list-modal"
          >
            <span
              className="close icon-close"
              onClick={() => setOpen(false)}
              onKeyDown={handleKeyDown}
            ></span>

            <>
              <span className="title">🟡 Tu lista de espera</span>
              <ul className="list-modal--list">
                <li className={`list-modal--list--item`}>
                  Martin Olivas Sempere.
                </li>
                <li className={`list-modal--list--item`}>
                  Julián Verde Bustos.
                </li>
                <li className={`list-modal--list--item`}>
                  Alejandro Contreras Sandoval.
                </li>
                <li className={`list-modal--list--item`}>
                  Martin Olivas Sempere.
                </li>
                <li className={`list-modal--list--item`}>
                  Gisella Romero Bolaños.
                </li>
                <li className={`list-modal--list--item`}>
                  Luciana Patricia Gomez Gomez.
                </li>
              </ul>
            </>
          </Modal>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <CardCopyKey
              phrase="Asegura Meta 1 Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={CalendarDirectorIcon}
              alt="calendar director"
              width="184"
              height="184"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="sx" align="center">
              ¡Súper bien Director(a) 🟡!
              <br />
              Para iniciar con el <b>Desafío C:</b>
            </Paragraph>
            <Paragraph size="sx" align="center">
              Confirma en <b>Mi Desempeño</b>, si tu
              <br />
              Coordinador(a) 🔴 ya logró completar
              <br />
              la <b>Meta 1.</b>
            </Paragraph>
            <Paragraph size="sx" align="center">
              Recuerda que la hora límite para lograr esta
              <br />
              Meta es a las 9:30 p.m. En ese momento se
              <br />
              establecerá el cronograma definitivo para el
              <br />
              EPA.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Zona de Aprendizaje.</b>
              <br />
              (Reagendamiento EPA).
            </Paragraph>
            <Paragraph align="center" size="sx">
              Si el Coordinador Luis Carlos Sarmiento no ha
              <br />
              logrado la Meta 1 antes de las 9:30 pm, tendrás
              <br />
              que agendar nuevamente el EPA para darle más
              <br />
              tiempo al equipo.
              <br />
              <br />A continuación te explicamos cómo hacerlo...
            </Paragraph>
            <img
              src={LearningZone}
              alt="learning zone"
              width="246"
              height="246"
            />
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              styles={{
                position: "relative",
                marginTop: "75px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Dirígete a la parte superior de la pantalla y<br />
              presiona sobre el botón que despliega el menú
              <br />
              principal.
            </Paragraph>

            <img
              src={ContenedorZonaAprendizaje}
              alt="group-873"
              width="343"
              height="151"
            />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Elige la opción <b>Agendamientos</b> dentro del menú
              <br />
              principal.
            </Paragraph>
            <img
              src={ContenedorZonaAprendizajeTwo}
              alt="group-874"
              widh="343"
              height="147"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Dentro del apartado agendamientos accede a<br />
              la opción EPA y reagenda el EPA en caso de ser
              <br />
              necesario.
            </Paragraph>

            <img
              src={ContenedorZonaAprendizajeThree}
              alt="group-873"
              widh="343"
              height="423"
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Recuerda que debes ponerte de acuerdo con el
              <br />
              equipo para definir la fecha del nuevo EPA.
            </Paragraph>
            <img
              src={ContenedorZonaAprendizajeFour}
              alt="group-873"
              widh="343"
              height="348"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
            <img
              src={CalendarDirectorIcon}
              alt="group-873"
              widh="184"
              height="184"
            />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Puede que el Día 1, 2 y 3 de tus dos
              <br />
              Coordinadores no esté sincronizado para el
              <br />
              mismo día, por ejemplo, mientras que estás con
              <br />
              tu Coordinador(a) #1 en Día 2, tu
              <br />
              Coordinador(a) #2 podría estar en Día 1.
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              style={{
                maxWidth: "364px",
              }}
            >
              Deberás completar los desafíos de las misiones
              <br />
              diarias en el cronograma del EPA de cada
              <br />
              Coordinador, es decir, si ya hiciste los desafíos
              <br />
              del Día 2 con tu Coordinador(a) #1, deberás
              <br />
              también realizar los desafíos del Día 2 del
              <br />
              cronograma del Coordinador(a) #2.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={7} visibility={step === 7 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Dirígete a <b>Mi Progreso</b> y visualiza en qué fecha
              <br />
              se realizarán los Día 1, 2 y 3.
            </Paragraph>
            <img
              src={ContenedorTutorial}
              alt="contenedor-tutorial"
              widh="289"
              height="413"
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={8} visibility={step === 8 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Actividad confirmación fechas misiones diarias
              <br />
              del Día 1 para los EPAs de tus Cordinadores.
            </Paragraph>

            <Paragraph
              size="s"
              align="center"
              style={{
                maxWidth: "364px",
              }}
            >
              ¿Cuál es la fecha del Día 1 de tu Coordinador(a)
              <br />
              <b>Luis Carlos Sarmiento Angulo?</b>
            </Paragraph>

            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 1
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 2
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 3
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 9}
            className="confirmation-register-modal small"
          >
            <Paragraph align="center" size="s">
              <b>¡Ups!</b>
            </Paragraph>
            <Paragraph align="center" size="s">
              Parece que ingresaste las fechas
              <br />
              incorrectas.
            </Paragraph>
            <Paragraph align="center" size="s">
              Inténtalo nuevamente.
            </Paragraph>
            <Button
              size="small"
              styles={{
                position: "relative",
                marginLeft: "33px",
              }}
              onClick={() => setStep(step + 1)}
            >
              ¡Vale!
            </Button>
          </Modal>
          <Step order={10} visibility={step === 10 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Excelente Director(a)!</b>
              <br />
              Ingresaste las fechas correctas del cronograma.
            </Paragraph>
            <img src={Contenedorcrono} alt="Contenedorcrono" />
            <Paragraph align="center" size="s">
              ”Los líderes de cuarto nivel se alinean con otros
              <br />
              líderes para un propósito en común.”
            </Paragraph>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={11} visibility={step === 11 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Genial!</b>
              <br />
              Has completado el<b>Desafío C.</b>
            </Paragraph>

            <img src={Genial} alt="genial" widh="226" height="226" />
            <CardCopyKey
              phrase="Preselección Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "36px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default DirectorRulesDay;
