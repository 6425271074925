import Background from "../../templates/background";
import Tab from "../../atoms/tab";
import Tabs from "../../molecules/tabs";
import { ReactComponent as MainLogo } from "../../../resources/images/main-logo.svg";
import BadgeTarget from "../../../resources/images/badge-target.png";
import Megaphone from "../../../resources/images/megaphone.png";
import ChallengeModal from "../../molecules/challenge-modal";
import Card from "../../atoms/card";
import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import WelcomeModal from "../../organisms/welcome-modal";
import PortalBridge from "../../atoms/portal-bridge";
import Button from "../../atoms/button";
import { API, ROLES } from "../../../middleware/utils/constants";
import axios from "axios";
import CarouselModal from "../../molecules/carousel-modal";
import CarouselSlide from "../../atoms/carousel-slide";
import InfoBox from "../../molecules/info-box";
import Paragraph from "../../atoms/paragraph";
import MyProgress from "../../organisms/my-progress";
import Menu from "../../organisms/menu";
import MyValidatorsTab from "../../organisms/my-validators-tab";
import { TabsContext } from "../../../middleware/providers/tabs-context";
import MyPerformance from "../../organisms/my-performance";
import Modal from "../../atoms/modal";
import Recognition from "../../../resources/images/recognition-medal.svg";
import TimeManagement from "../../../resources/images/time-management-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import ClapHand from "../../../resources/images/clap-candidate.png";
import { getSchedule } from "../../../middleware/services/common";
import { indexes } from "../../../middleware/utils/constants";
import { formatDateLong } from "../../../middleware/utils/formatDate";
import MyCoordinatorValidatorsTab from "../../organisms/my-coordinator-validators-tab";
import InfoDirectorBox from "../../molecules/info-director-box";
const MissionsPage = () => {
  const { user, medalModal, setMedalModal, login, setCurrentChallenge } =
    useContext(UserContext);
  const { activeTab, setActiveTab } = useContext(TabsContext);
  //  const navigate = useNavigate();

  const [role, setRole] = useState("");
  const roleName = ROLES[role]?.roleName;
  const level = ROLES[role]?.level;

  const [refresh, setRefresh] = useState(false);

  const [day, setDay] = useState("");
  //const [days, setDays] = useState([]);
  const [challenges, setChallenges] = useState([]);

  const [activeChallenge, setActiveChallenge] = useState(null);
  const [selector, setSelector] = useState("first");

  const [challengeOpen, setChallengeOpen] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [welcomeInductionModal, setWelcomeInductionModal] = useState(false);
  const [challengeModalContent, setChallengeModalContent] = useState({});
  const [welcomeFirstDayModal, setWelcomeFirstDayModal] = useState(false);
  const [welcomeSecondDayModal, setWelcomeSecondDayModal] = useState(false);
  const [welcomeThirdDayModal, setWelcomeThirdDayModal] = useState(false);
  const [welcomeFilterDayModal, setWelcomeFilterDayModal] = useState(false);
  const [welcomeAligmentDayModal, setWelcomeAligmentDayModal] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [completedChallenge, setCompletedChallenge] = useState(false);
  const location = useLocation();

  const MEDALS = {
    recognition: {
      img: Recognition,
      text: "Reconocimiento",
    },
    timeManagement: {
      img: TimeManagement,
      text: "Manejo del tiempo",
    },
    justInTime: {
      img: JustInTime,
      text: "Justo a tiempo",
    },
  };
  const [schedule, setSchedule] = useState([]);

  const fetchData = async (userId) => {
    const schedule = await getSchedule(userId);
    setSchedule(schedule);
  };

  useEffect(() => {
    if (user?._id) {
      fetchData(user?._id);
    }
  }, [user]);
  const openChallengeModal = (challenge, index) => {
    const icon = ROLES[role]?.challenges?.[activeChallenge?.day]?.[index]?.icon;

    const itemsMedal = challenge?.description.filter((item) => item?.medal);
    const challengeModal = {
      icon: icon,
      name: challenge?.nameChallenge,
      items: challenge?.description.map((item, indexItem) => {
        return (
          item?.activity && {
            icon: item?.icon,
            text: item?.activity,
          }
        );
      }),
      itemsMedal: itemsMedal.map((item, indexItem) => {
        return (
          item?.medal && {
            icon: item?.icon,
            text: item?.medal,
          }
        );
      }),
    };

    setChallengeModalContent(challengeModal);
    setChallengeOpen(true);
  };

  const finishChallenge = () => {
    axios
      .put(
        `${API.CHALLENGE_COMPLETED.url}/${user?._id}/${activeChallenge?.idChallenges}`
      )
      .then((response) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          setCompletedChallenge(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API.GET_INFORMATION.url}`)
      .then((response) => {
        if (response?.status === 200) {
          const newRole =
            response?.data?.data?.role?.indexOf("new") > -1
              ? response?.data?.data?.role?.split(" ")?.[1]
              : response?.data?.data?.role;
          setRole(newRole);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${API.PROGRESS_POINT.url}`)
      .then((response) => {
        const data = response.data.data;
        if (data?.message !== "This user has no challenges yet") {
          setChallenges(data);
          setDay(data[0]?.nameDay);
          setChallenges(data);

          const active =
            data?.find((challenge, index) => {
              if (data[index - 1]?.status && !challenge?.status) {
                return challenge;
              }
            }) || data[0];

          setActiveChallenge(active);
          setCurrentChallenge(active);
          getSelectorPosition(active);

          if (!data[0]?.status && active?.day === "induction day") {
            setWelcomeInductionModal(true);
          }
          if (!data[0]?.status && active?.day === "rules day") {
            setWelcomeModal(true);
          }
          if (!data[0]?.status && active?.day === "day one") {
            setWelcomeFirstDayModal(true);
          }

          if (active?.day === "day two" && !data[0]?.status) {
            setWelcomeSecondDayModal(true);
          }

          if (active?.day === "day three" && !data[0]?.status) {
            setWelcomeThirdDayModal(true);
          }

          if (active?.day === "filter day" && !data[0]?.status) {
            setWelcomeFilterDayModal(true);
          }

          if (active?.day === "alignment day" && !data[0]?.status) {
            setWelcomeAligmentDayModal(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setChallenges([]);
      });

    if (location.state?.refresh) {
      login();
    }
  }, [refresh, location.state, completedChallenge]);

  useEffect(() => {
    if (
      (medalModal !== "" &&
        medalModal?.typeMedals !== "wildcard" &&
        medalModal !== "continue") ||
      activeChallenge?.timeManagement > 0
    ) {
      if (
        (activeChallenge?.day === "induction day" ||
          activeChallenge?.day === "day one" ||
          activeChallenge?.day === "day two" ||
          activeChallenge?.day === "day three") &&
        activeChallenge?.nameChallenge === "Desafío A"
      ) {
        setCompletedChallenge(true);
      }
    }
  }, [medalModal, activeChallenge]);

  const getSelectorPosition = (active) => {
    switch (active?.day) {
      case "induction":
        setSelector("first");
        break;
      case "induction day":
        setSelector("first");
        break;
      case "rules day":
        setSelector("second");
        break;
      case "day one":
        setSelector("third");
        break;
      case "day two":
        setSelector("fourth");
        break;
      case "day three":
        setSelector("fifth");
        break;
      case "filter day":
        setSelector("sixth");
        break;
      case "alignment day":
        setSelector("seventh");
        break;
      case "epa day":
        setSelector("eighth");
        break;
      case "end of epa":
        setSelector("ninth");
        break;
      default:
        setSelector("first");
        break;
    }
  };

  const handleMouseMove = (e) => {
    e.stopPropagation();
    const mouseY = e.clientY;
    const windowHeight = window.innerHeight;

    if (mouseY < windowHeight / 2) {
      setMenuShow(false);
    }
  };
  const titleText = `¡Bienvenido(a) a la Plataforma <br/> ACDC!`;
  const formattedTitle = titleText.split("<br/>").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
  return (
    <Background className={`missions-page ${activeTab === 2 ? "auto" : ""}`}>
      <header>
        <div className={`logo logo--${role}`}>
          <MainLogo />
        </div>
        <span
          className="icon-btn-menu icon right"
          onClick={() => setMenuShow(!menuShow)}
          onKeyDown={() => {}}
        ></span>
      </header>
      <h4 className="title"> Misiones Diarias</h4>
      <Tabs>
        <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
          Mi Progreso
        </Tab>
        {(user?.role === "candidate" || user?.role === "new candidate") &&
          ["filter day", "alignment day", "epa day", "end of epa"].find(
            (day) => activeChallenge?.day === day
          ) && (
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Mis Validadores
            </Tab>
          )}
        {user?.role === "host" &&
          ["induction day", "rules day"].find(
            (day) => activeChallenge?.day === day
          ) && (
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Mis Validadores
            </Tab>
          )}
        {(user?.role === "host" || user?.role === "new host") &&
          !["induction day", "rules day"].find(
            (day) => activeChallenge?.day === day
          ) && (
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Mis Aspirantes
            </Tab>
          )}
        {(user?.role === "coordinator" || user?.role === "new coordinator") && (
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Mis Anfitriones
          </Tab>
        )}
        {(user?.role === "director" || user?.role === "new director") && (
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Mis Anfitriones
          </Tab>
        )}
        {(user?.role === "captain" || user?.role === "new captain") && (
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Mis Anfitriones
          </Tab>
        )}
        <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
          Mi Desempeño
        </Tab>
      </Tabs>
      {activeTab === 0 && (
        <MyProgress
          openChallengeModal={openChallengeModal}
          selector={selector}
          refresh={refresh}
          day={day}
          role={role}
        />
      )}
      {activeTab === 1 && (
        <>
          {role === "coordinator" && (
            <>
              <MyCoordinatorValidatorsTab
                idChallenge={activeChallenge?.idChallenges}
                activeChallenge={activeChallenge}
              />
            </>
          )}
          {role !== "coordinator" && (
            <>
              <MyValidatorsTab
                idChallenge={activeChallenge?.idChallenges}
                activeChallenge={activeChallenge}
              />
            </>
          )}
        </>
      )}
      {activeTab === 2 && <MyPerformance />}
      <ChallengeModal
        icon={challengeModalContent?.icon}
        name={challengeModalContent?.name}
        isOpen={challengeOpen}
        onClose={() => setChallengeOpen(false)}
      >
        <Card type="quinary">
          <div className="card-list">
            {challengeModalContent?.items?.map((item, index) => (
              <div className="item" key={item.icon + index}>
                <span className={`icon ${item?.icon}`}></span>
                <div className="item--text">{item?.text}</div>
              </div>
            ))}
          </div>
        </Card>

        {challengeModalContent?.itemsMedal?.length > 0 && (
          <Card type="quinary">
            <div className="card-list medals">
              {challengeModalContent?.itemsMedal?.map(
                (item, index) =>
                  item?.icon && (
                    <div className="item" key={item.icon + index}>
                      <span className={`icon ${item?.icon}`}></span>
                      <div className="item--text">{item?.text}</div>
                    </div>
                  )
              )}
            </div>
          </Card>
        )}
      </ChallengeModal>

      {role === "coordinator" && (
        <>
          <WelcomeModal
            role={role}
            roleName={roleName}
            level={level}
            isOpen={welcomeModal}
            day={day}
            onClose={() => setWelcomeModal(false)}
          />
          <CarouselModal isOpen={welcomeInductionModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day}⚜️</>}
                title={formattedTitle}
                paragraph={
                  <>
                    Hoy debes sincronizarte con tus Anfitriones para el
                    Entrenamiento del ABC de la Convocatoria que está agendado.
                    <br />
                    <br />
                    <div className="box-text-hour">
                      Desde <span className="text-hour">8:00 am</span> Hasta{" "}
                      <span className="text-hour">8:30 am</span>
                    </div>
                    <br />
                    Al hacerlo, tus Anfitriones estarán preparados para realizar
                    la convocatoria a los nuevos Aspirantes a la comunidad ACDC
                    de manera efectiva.
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu
                <br />
                <b>Meta 1.</b>
                <br />
                <br />
                Asegurar que tus dos Anfitriones, logren agendar cada uno a seis
                Aspirantes para el EPA.
                <br />
                <br />
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus <br />
                metas siempre desde <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>{activeChallenge?.nameDay}</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia <b>{activeChallenge?.nameDay}</b>{" "}
                  en el Cronograma que verás en la pantalla, encontrarás, paso a
                  paso, las actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="sx" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán <br />
                contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeInductionModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeFirstDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day}🔐🔐🔒</>}
                title={formattedTitle}
                paragraph={
                  <>
                    Hoy tus Anfitriones empiezan el<b>Día {day}</b>, junto{" "}
                    <br />
                    con ellos fortalecerás el Hambre para <br />
                    lograr la <b>Meta 2</b>.
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu
                <br />
                <b>Meta 2.</b>
                <br />
                <br />
                Asegurar que tus dos Anfitriones logren <br />
                preseleccionar cada uno a sus tres
                <br /> Aspirantes de mejor Desempeño.
                <br />
                <br />
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus <br />
                metas siempre desde <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>{activeChallenge?.nameDay}</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia <b>{activeChallenge?.nameDay}</b>{" "}
                  en el Cronograma que verás en la pantalla, encontrarás, paso a
                  paso, las actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán <br />
                contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeFirstDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeSecondDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day} 🔐🔐🔒</>}
                title="¡Bienvenido(a) a la Plataforma ACDC!"
                paragraph={
                  <>
                    Hoy continuarás con el Día 2, apoyarás a tus Anfitriones a
                    completar su Meta 2 y fortalecerás tu HUMILDAD para lograr
                    tu
                    <br /> Meta 2.
                    <br />
                    <br />
                  </>
                }
              ></InfoBox>
            </CarouselSlide>

            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu <br /> <b>Meta 2.</b>
                <br />
                <br />
                <br />
                Asegurar que tus dos Anfitriones logren <br />
                preseleccionar cada uno a sus tres <br />
                Aspirantes de mejor Desempeño.
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus metas siempre desde
                <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día 2</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día 2 en el Cronograma que verás
                  en la pantalla, encontrarás, paso a paso, las actividades que
                  debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeSecondDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeThirdDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day}🔐🔐🔒</>}
                title="¡Bienvenido(a) a la Plataforma ACDC!"
                paragraph={
                  <>
                    Hoy junto con tus Anfitriones han <br />
                    despertado su HAMBRE y <br />
                    HUMILDAD, ahora fortalecerán el
                    <br /> HONOR para lograr la Meta 2.
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu <br /> <b>Meta 2.</b>
                <br />
                <br />
                <br />
                Asegurar que tus dos Anfitriones logren <br />
                preseleccionar cada uno a sus tres <br />
                Aspirantes de mejor Desempeño.
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus metas siempre desde
                <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día 3</h3>

                <PortalBridge id="day-rules" role={role} selector={"fourth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia Día 3 en el Cronograma que verás
                  en la pantalla, encontrarás, paso a paso, las actividades que
                  debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeThirdDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeFilterDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day} 📝</>}
                title={
                  <>
                    ¡Bienvenido(a) a la Plataforma <br /> ACDC!
                  </>
                }
                paragraph={
                  <>
                    ¡Has llegado al Día de Filtro
                    <br />
                    Coordinador(a)! Hoy tu prioridad es aplicar <br />
                    la estrategia de Sincronización con tus <br />
                    Anfitriones, para que logren sincronizarse <br />
                    con sus Aspirantes en el entrenamiento ABC
                    <br /> Filtro.
                    <br />
                    ¡Recuerda que cada Anfitrión debe llegar a<br />
                    este día con al menos 3 Aspirantes
                    <br />
                    preseleccionados!
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <img
                src={BadgeTarget}
                alt="badge-target"
                className="badge-target"
              />
              <Paragraph size="s" align="center">
                Te encuentras cumpliendo tu
                <br />
                <b> Meta 2.</b>
              </Paragraph>
              <Paragraph size="s" align="center">
                Asegurar que tus dos Anfitriones logren
                <br />
                preseleccionar cada uno a sus tres <br />
                Aspirantes con mejor Desempeño.
              </Paragraph>
              <Paragraph size="xs" align="center">
                Recuerda que puedes revisar el estado de tus metas siempre desde{" "}
                <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día de Filtro</h3>
                <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día de Filtro en el Cronograma
                  que verás en la pantalla, encontrarás, paso a paso, las
                  actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeFilterDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeAligmentDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day} 🏁 </>}
                title={
                  <>
                    ¡Bienvenido(a) a la Plataforma <br /> ACDC!
                  </>
                }
                paragraph={
                  <>
                    ¡Has llegado al día de Alineamiento! Hoy <br />
                    conocerás tus funciones antes, durante y <br />
                    después del EPA. Guía a tus Anfitriones <br />
                    hacia la Meta 2 para comenzar tu <br />
                    preparación hacia el siguiente nivel de
                    <br />
                    liderazgo: Formación de líderes.
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <img
                src={BadgeTarget}
                alt="badge-target"
                className="badge-target"
              />
              <Paragraph size="s" align="center">
                Te encuentras cumpliendo tu
                <br />
                Meta 2.
              </Paragraph>
              <Paragraph size="s" align="center">
                Asegurar que tus dos Anfitriones logren <br />
                preseleccionar cada uno a sus tres
                <br />
                Aspirantes de mejor Desempeño.
              </Paragraph>
              <Paragraph size="xs" align="center">
                Recuerda que puedes revisar el estado de tus metas siempre desde{" "}
                <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día de Alineamiento</h3>
                <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día de Filtro en el Cronograma
                  que verás en la pantalla, encontrarás, paso a paso, las
                  actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeAligmentDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
        </>
      )}
      {role === "director" && (
        <>
          <CarouselModal isOpen={welcomeInductionModal}>
            <CarouselSlide>
              <InfoDirectorBox
                title="Día de Inducción ⚜️"
                paragraph={
                  <>
                    Hoy en tu rol como Director(a) ¡Inicias este
                    <br />
                    día con motivación para formar líderes!
                    <br />
                    Sincronizarás a los equipos de tus dos
                    <br />
                    Coordinadores que tienen EPAs en distintas
                    <br />
                    fechas. Al hacerlo, acompañarás a tus
                    <br />
                    Coordinadores para que completen el
                    <br />
                    entrenamiento ABC Convocatoria con sus
                    <br /> Anfitriones.
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu
                <br />
                <b>Meta 2.</b>
                <br />
                <br />
                Asegurar que tus dos Coordinadores logren
                <br />
                que sus dos Anfitriones agenden cada uno a<br />
                sus seis Aspirantes.
                <br />
                <br />
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus <br />
                metas siempre desde <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>{activeChallenge?.nameDay}</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia <b>{activeChallenge?.nameDay}</b>{" "}
                  en el Cronograma que verás en la pantalla, encontrarás, paso a
                  paso, las actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="sx" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Capitán(a) 🔵 estarán <br />
                contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeInductionModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>

          <CarouselModal isOpen={welcomeModal}>
            <CarouselSlide>
              <InfoDirectorBox
                title={<>Día de Reglas del Juego 🎮</>}
                paragraph={
                  <>
                    Para seguir <b>formando líderes</b>, hoy deberás
                    <br />
                    asegurarte que tus 2 Coordinadores logren
                    <br />
                    un alto desempeño completando su <b>Meta 1.</b>
                    <br />
                    así como tú lo lograste.
                    <br />
                    Hoy conocerás los pensamientos y las
                    <br />
                    acciones fundamentales para completar la
                    <br />
                    <b> Meta 2</b> a partir de la lectura del Arte y la
                    <br />
                    Ciencia de la Formación de líderes.
                    <br />
                    ¡Comencemos!
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu <br /> <b>Meta 2.</b>
                <br />
                <br />
                Asegurar que tus dos Coordinadores logren<br/> 
                que sus dos Anfitriones preseleccionen cada<br/>
                uno a sus tres Aspirantes de mejor<br/> Desempeño.
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus<br/> metas siempre desde <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día de Reglas</h3>
                <PortalBridge id="day-rules" role={role}>
                  {schedule?.map((day, index) => (
                    <span className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día de Reglas en el Cronograma
                  que verás en la pantalla, encontrarás, paso a paso, las
                  actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-megaphone-icon"></div>
              <Paragraph align="center" size="sx">
                😉<b>Recordatorio</b> 👇🏼
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                
TWOBOT 🤖 y tu Capitán(a) 🔵 estarán contigo hasta el final.
              </Paragraph>
              <br />
              <br />
              <Button
                size="small"
                onClick={() => setWelcomeModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeFirstDayModal}>
            <CarouselSlide>
            <InfoDirectorBox
                title={<><b>Día 1 🔐🔒🔒</b></>}
                paragraph={
                  <>
                                   
                                   Hoy fortalecerás el <b>HAMBRE</b>  para<br/>
                                    lograr la Meta 2. Ten presente que un<br/>
                                    líder de cuarto nivel logra resultados<br/>
                                    aún cuando no está presente. Estos<br/>
                                    resultados se obtienen gracias al<br/>
                                    desempeño y crecimiento exponencial<br/>
                                    de otros líderes bajo su guía.
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu
                <br />
                <b>Meta 2.</b>
                <br />
                <br />
                Asegurar que tus dos Coordinadores logren<br/>
                que sus dos Anfitriones preseleccionen cada<br/>
                 uno a sus tres Aspirantes de mejor<br/>
                Desempeño.
                <br />
                <br />
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus <br />
                metas siempre desde <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>{activeChallenge?.nameDay}</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia <b>{activeChallenge?.nameDay}</b>{" "}
                  en el Cronograma que verás en la pantalla, encontrarás, paso a
                  paso, las actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Capitán(a) 🔵 estarán <br />
                contigo hasta el final.
              </Paragraph>
              <Button size="small" onClick={() => setWelcomeFirstDayModal(false)}>
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeSecondDayModal}>
            <CarouselSlide>
              <InfoDirectorBox
              
              
                title={<>{day} 🔐🔐🔒</>}
                paragraph={
                  <>
                   Hoy fortalecerás la <b>HUMILDAD</b> para<br/> 
                   lograr la <b>Meta 2</b>. Ayudarás a construir y<br/>
                  a restaurar la confianza al señalar al<br/>
                  Coordinador sus fortalezas y<br/>
                  habilidades. Con tus fortalezas también<br/>
                   podrás lograr la Meta 2.
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>

            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu <br /> <b>Meta 2.</b>
                <br />
                <br />
                <br />
                Asegurar que tus dos Coordinadores logren <br />
                que sus dos Anfitriones preseleccionen cada<br />
                uno a sus tres Aspirantes de mejor<br/>
                Desempeño.
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus metas siempre desde
                <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día 2</h3>
                <PortalBridge id="day-rules" role={role} selector={"third"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día 2 en el Cronograma que verás
                  en la pantalla, encontrarás, paso a paso, las actividades que
                  debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Capitán(a) 🔵  estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeSecondDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeThirdDayModal}>
            <CarouselSlide>
              <InfoDirectorBox
                title={<>{day}🔐🔐🔒</>}
                paragraph={
                  <>
                    Hoy empiezas el Día 3, fortalecerás el<br/>
                    <b>HONOR</b> junto con tu <b>HAMBRE</b> y<br/>
                     <b>HUMILDAD</b> para lograr la <b>Meta 2.</b>
                     <br/> Director(a), recuerda que la credibilidad<br/>
                      del líder cae en su habilidad para ayudar a<br/>
                       otros líderes para que obtengan<br/>
                        resultados y piensen correctamente como<br/>
                         un líder. Ese es tu objetivo el día de hoy.
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-box-badge-target-icon"></div>
              <Paragraph align="center" size="sx">
                Te encuentras cumpliendo tu <br /> <b>Meta 2.</b>
                <br />
                <br />
                <br />
                Asegurar que tus dos Coordinadores logren <br />
                que sus dos Anfitriones preseleccionen cada<b/>
                uno a sus tres 
                Aspirantes de mejor<br/> Desempeño.
                <br />
                <br />
                <br />
                Recuerda que puedes revisar el estado de tus metas siempre desde
                <b> Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día 3</h3>

                <PortalBridge id="day-rules" role={role} selector={"fourth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>

                <p>
                  Deslizando el selector hacia Día 3 en el Cronograma que verás
                  en la pantalla, encontrarás, paso a paso, las actividades que
                  debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Capitán(a) 🔵 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeThirdDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeFilterDayModal}>
            <CarouselSlide>
              <InfoDirectorBox

                title={<>{day} 📝</>}
                paragraph={
                  <>
                   
                   ¡Has llegado al Día de Filtro Director(a)!<br/>

Los líderes de cuarto nivel no son tan<br/>
 buenos como el buen desempeño de sus<br/>
  líderes, por esta razón estarás atento(a) al<br/>
  desempeño que lleva tu Coordinador(a)<br/>
   que se encuentra en este día; lo(la)<br/>
    aconsejarás y lo(la) guiarás para que lo<br/> logre.
                  </>
                }
              ></InfoDirectorBox>
            </CarouselSlide>
            <CarouselSlide>
              <img
                src={BadgeTarget}
                alt="badge-target"
                className="badge-target"
              />
              <Paragraph size="s" align="center">
                Te encuentras cumpliendo tu
                <br />
                <b> Meta 2.</b>
              </Paragraph>
              <Paragraph size="sx" align="center">
              Asegurar que tus dos Coordinadores logren<br/>
              que sus dos Anfitriones preseleccionen cada<br/>
              uno a sus tres Aspirantes de mejor<br/> Desempeño.
              </Paragraph>
           
              <Paragraph size="xs" align="center">
                Recuerda que puedes revisar el estado de tus metas siempre desde{" "}
                <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día de Filtro</h3>
                <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día de Filtro en el Cronograma
                  que verás en la pantalla, encontrarás, paso a paso, las
                  actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Capitán(a) 🔵  estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeFilterDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
          <CarouselModal isOpen={welcomeAligmentDayModal}>
            <CarouselSlide>
              <InfoBox
                role={role}
                roleName={roleName}
                level={level}
                day={<>{day} 🏁 </>}
                title={
                  <>
                    ¡Bienvenido(a) a la Plataforma <br /> ACDC!
                  </>
                }
                paragraph={
                  <>
                    ¡Has llegado al día de Alineamiento! Hoy <br />
                    conocerás tus funciones antes, durante y <br />
                    después del EPA. Guía a tus Anfitriones <br />
                    hacia la Meta 2 para comenzar tu <br />
                    preparación hacia el siguiente nivel de
                    <br />
                    liderazgo: Formación de líderes.
                  </>
                }
              ></InfoBox>
            </CarouselSlide>
            <CarouselSlide>
              <img
                src={BadgeTarget}
                alt="badge-target"
                className="badge-target"
              />
              <Paragraph size="s" align="center">
                Te encuentras cumpliendo tu
                <br />
                Meta 2.
              </Paragraph>
              <Paragraph size="s" align="center">
                Asegurar que tus dos Anfitriones logren <br />
                preseleccionar cada uno a sus tres
                <br />
                Aspirantes de mejor Desempeño.
              </Paragraph>
              <Paragraph size="xs" align="center">
                Recuerda que puedes revisar el estado de tus metas siempre desde{" "}
                <b>Mi Desempeño.</b>
              </Paragraph>
            </CarouselSlide>
            <CarouselSlide>
              <div className="info-section-bridge">
                <h3>Día de Alineamiento</h3>
                <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                  {schedule?.map((day, index) => (
                    <span key={indexes[index]} className={indexes[index]}>
                      {formatDateLong(day.date)}
                    </span>
                  ))}
                </PortalBridge>
                <p>
                  Deslizando el selector hacia Día de Filtro en el Cronograma
                  que verás en la pantalla, encontrarás, paso a paso, las
                  actividades que debes realizar.
                </p>
              </div>
            </CarouselSlide>
            <CarouselSlide>
              <img src={Megaphone} alt="megaphone" className="megaphone" />
              <Paragraph size="s" align="center">
                <b>😉 Recordatorio 👇🏼</b>
                <br />
                <br />
                No hay respuestas correctas o incorrectas.
                <br />
                <br />
                TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo hasta el final.
              </Paragraph>
              <Button
                size="small"
                onClick={() => setWelcomeAligmentDayModal(false)}
              >
                ¡Vale!
              </Button>
            </CarouselSlide>
          </CarouselModal>
        </>
      )}

      <Menu
        role={role}
        isShow={menuShow}
        onTouchMove={handleMouseMove}
        onClick={() => setMenuShow(false)}
      />
      <Modal
        isOpen={completedChallenge && !activeChallenge?.status}
        className="confirmation-register-modal"
      >
        <div className="confirmation-register-container">
          <div className="light">
            <img src={ClapHand} alt="Clap Candidate" />
          </div>
          <Paragraph align="center" size="s">
            ¡Genial!
            <br />
            Has completado el <b>Desafío A.</b>
            <br />
            <br />
            Ya puedes continuar con el <b>Desafío B</b> del{" "}
            {activeChallenge?.nameDay}.
            <br />
            <br />
            <b>👋🏻 Ya nos vemos.</b>
          </Paragraph>
          <Button
            size="small"
            onClick={() => {
              finishChallenge();
            }}
          >
            ¡Vale!
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={
          medalModal !== "" &&
          medalModal?.typeMedals !== "wildcard" &&
          medalModal !== "continue"
        }
        className="confirmation-register-modal medal-modal"
      >
        <div className="confirmation-register-container">
          <img
            src={MEDALS[medalModal?.typeMedals]?.img}
            alt="Time Management"
            style={{ opacity: medalModal.medal ? "1" : "0.5" }}
          />
          {medalModal.medal ? (
            <>
              <Paragraph align="center" size="s">
                ¡Has ganado una Medalla de{" "}
                <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
              </Paragraph>
              <Paragraph align="center" size="s">
                Estas te servirán para medir tu desempeño y poder ser uno de los
                3 preseleccionados para asistir al EPA.
              </Paragraph>
            </>
          ) : (
            <Paragraph align="center" size="s">
              ¡No has ganado una Medalla de{" "}
              <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
            </Paragraph>
          )}
          <Button
            size="small"
            onClick={() => {
              setMedalModal("");
            }}
          >
            ¡Vale!
          </Button>
        </div>
      </Modal>
    </Background>
  );
};

export default MissionsPage;
